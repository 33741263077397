<template>
    <div class="notes">
        <div class="note"  :class="{ full: !grid}" v-for="(note) in notes" :key="note.id">
            <div class="priority" :class="note.priority_level"></div>
            <div class="text-content">
                <div class="note-header">
                    <!-- <input class="note-editor"
                           v-if="note.titleEditModeEnabled"
                           v-model="note.title"
                           @blur="[note.titleEditModeEnabled = false, note.date = new Date(Date.now()).toLocaleString(), $emit('update')]"
                           @keyup.enter="[note.titleEditModeEnabled=false, note.date = new Date(Date.now()).toLocaleString(), $emit('update')]"
                           v-focus> -->
                    <p>{{ note.title }} <small>{{ note.date }}</small> </p>
                    
                    <b-dropdown
                    variant="link"
                    size="sm"
                    toggle-class="text-decoration-none "
                    no-caret
                    >
                    <template v-slot:button-content class="vertical-menu">
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25 text-muted"
                      />
                    </template>

                    <!-- <b-dropdown-item
                      class="btn-sm p-0 text-muted"
                      v-b-toggle.sidebar-view-note
                      >
                      <feather-icon
                        icon="EyeIcon"
                        size="13"
                        class="mr-50 text-primary"
                      />
                      <span class="">View</span>
                    </b-dropdown-item> -->

                    <b-dropdown-item
                      class="btn-sm p-0 text-muted"
                      v-b-toggle.sidebar-edit-note
                      @click="selectedNote=note"
                      >
                      <!-- @click="editRecord(data)" -->
                      <feather-icon
                        icon="EditIcon"
                        size="13"
                        class="mr-50 text-primary"
                      />
                      <span class="">Edit</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      class="btn-sm p-0"
                      @click="removeNote(note)"
                      >
                      <feather-icon
                        icon="TrashIcon"
                        size="13"
                        class="mr-50 text-primary"
                      />
                      <span class="">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                    <!-- <button class="close-btn" @click="removeNote(note.hashid)">x</button> -->
                </div>
                <div class="note-body">
                    <!-- <textarea class="note-editor"
                           v-if="note.descriptionEditModeEnabled"
                           v-model="note.description"
                           @blur="[note.descriptionEditModeEnabled = false, note.date = new Date(Date.now()).toLocaleString(), $emit('update')]"
                           @keyup.enter="[note.descriptionEditModeEnabled=false, note.date = new Date(Date.now()).toLocaleString(), $emit('update')]"
                           v-focus  rows=90 cols=75 /> -->
                    <b-card-text v-html="note.description"> </b-card-text>
                </div>
            </div>
        </div>
        <b-sidebar
            id="sidebar-edit-note"
            sidebar-class="sidebar-lg"
            bg-variant="white"
            shadow
            backdrop
            no-header
            width="50%"
            right
            >
            <template #default="{ hide }">
                <!-- Header -->
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    Edit Note
                </h5>
                <div>
                    <b-button
                    variant="outline-primary"
                    class="mr-2 min-w-100 px-3"
                    id="editcancel"
                    @click="hide"
                    pill
                    >
                    Cancel
                    </b-button>
        
                    <b-button variant="primary" @click="formUpdate()" class="px-3">
                    Update
                    </b-button>
                </div>
                </div>
                <!-- BODY -->
            <validation-observer ref="NoteForm" tag="form" style="overflow: hidden;">
                <b-form autocomplete="off" id="note-edit-form" class="p-2">
                <b-row>
                    <b-col md="12">
                    <b-form-group>
                        <validation-provider
                        #default="{ errors }"
                        name="Note Title"
                        rules="required"
                        >
                        <label class="required-l" for="name">Note Title</label>
                        <b-form-input
                            id="title"
                            v-model="selectedNote.title"
                            name="title"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Enter Note Title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    </b-col>
                    <b-col md="12">
                    <b-form-group>
                        <validation-provider
                        #default="{ errors }"
                        name="Note Description"
                        rules="required"
                        >
                        <label class="required-l" for="description">Note Description</label>
                        <!-- <b-form-textarea
                            id="description"
                            v-model="selectedNote.description"
                            name="description"
                            placeholder="Enter Note Description"
                            :state="errors.length > 0 ? false : null"
                            rows="20"
                        /> -->
                        <QuillInput
                            :value.sync="selectedNote.description"
                            :toolbar="true"
                            className="post-editor"
                            :mentionList="[]"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    </b-col>
                    <b-col md="6">
                    <b-form-group>
                        <validation-provider
                        #default="{ errors }"
                        name="Note Priority"
                        rules="required"
                        >
                        <label class="required-l" for="priority_level">Note Priority Level</label><br>
                        <div class="demo-inline-spacing">
                            <b-form-radio
                            v-model="selectedNote.priority_level"
                            plain
                            name="priority_level"
                            value="low"
                            >
                            Low
                            </b-form-radio>
                            <b-form-radio
                            v-model="selectedNote.priority_level"
                            plain
                            name="priority_level"
                            value="medium"
                            >
                            Medium
                            </b-form-radio>
                            <b-form-radio
                            v-model="selectedNote.priority_level"
                            plain
                            name="priorityLevel"
                            value="high"
                            >
                            High
                            </b-form-radio>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    </b-col>
                </b-row>
                </b-form>
                <div class="ml-2">
                <b-button
                    variant="outline-primary"
                    class="mr-2 min-w-100 px-3"
                    @click="hide"
                    pill
                >
                    Cancel
                </b-button>

                <b-button variant="primary" @click="formUpdate()" class="px-3">
                    Update
                </b-button>
                </div>
            </validation-observer>
                
            </template>
        </b-sidebar>
        <b-sidebar
            id="sidebar-view-note"
            sidebar-class="sidebar-lg"
            bg-variant="white"
            shadow
            backdrop
            no-header
            width="50%"
            right
            >
            <template #default="{ hide }">
                <!-- Header -->
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    View Note
                </h5>
                <div>
                    <b-button
                    variant="outline-primary"
                    class="mr-2 min-w-100 px-3"
                    id="editcancel"
                    @click="hide"
                    pill
                    >
                    Cancel
                    </b-button>
                </div>
                </div>
                <!-- BODY -->
                <b-row>
                    <b-col md="12">
                        <h1>Note Title</h1> : {{selectedNote.title}}
                    </b-col>
                    <b-col md="12">
                        <label for="name">Note Description</label> : {{selectedNote.description}}
                    </b-col>
                    <b-col md="12">
                        <label  for="name">Note  Priority Level</label> : {{selectedNote.priority_level}}
                    </b-col>
                </b-row>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
    import store from "@/store";
    import router from "@/router";
    import { ref, onUnmounted } from "@vue/composition-api";
    import {
      BRow,
      BCol,
      BAlert,
      BLink,
      BTabs,
      BTab,
      BCard,
      BCardText,
      BDropdown,
      BDropdownItem,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      VBTooltip,
      BBadge,
      BProgress,
      BProgressBar,
      BSidebar
    } from "bootstrap-vue";
    import Ripple from "vue-ripple-directive";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import { VueGoodTable } from "vue-good-table";
    import { ValidationProvider, ValidationObserver } from "vee-validate";
    import { required, email } from "@validations";
    import notesStoreModule from "@/views/notes/notesStoreModule";
    import QuillInput from "@/components/QuillInput.vue";
    
    import message from '@/views/notes/Message.vue'
    import newNoteCreator from "@/views/notes/NewNoteCreator.vue";
    import notes from "@/views/notes/Notes.vue";
    import search from "@/views/notes/Search.vue";
    import $ from "jquery";
    
    export default {
      components: {
        BRow,
        BCol,
        BAlert,
        BLink,
        BTabs,
        BTab,
        BCard,
        BCardText,
        BDropdown,
        BDropdownItem,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        VueGoodTable,
        BBadge,
        BProgress,
        BProgressBar,
        message,
        newNoteCreator,
        notes,
        search,
        BSidebar,
        ValidationProvider,
        ValidationObserver,
        required,
        QuillInput
      },
        name: "Notes",
        data() {
            return {
                selectedNote : {
                    hashid: null,
                    title: '',
                    description: '',
                    priority_level: 'low',
                    titleEditModeEnabled: false,
                    descriptionEditModeEnabled: false
                },
            }
        },
        props: {
            notes: {
                type: Array,
                required: true
            },
            grid: {
                type: Boolean,
                required: true
            }
        },
        methods: {
            removeNote(noteId) {
                this.$emit('remove', noteId)
            },
            formUpdate() {
            const self = this;
            const formData = new FormData();
            formData.append("description", self.selectedNote.description);
            formData.append("title", self.selectedNote.title);
            formData.append("priority_level",self.selectedNote.priority_level);
            formData.append("_method", "PATCH");

            let data = {};
            data.url = "notes/"+self.selectedNote.hashid;
            data.params = formData;

            this.isBusy = true;
            this.$store
                .dispatch("app/update", data)
                .then((res) => {
                $('#editcancel').click();
                $('#note-edit-form').trigger("reset");
                this.$emit('refresh');
                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: "Note Updated",
                    icon: "BellIcon",
                    variant: "success",
                    text: res.data.message,
                    },
                });
                })
                .catch((error) => {
                this.isBusy = false;
                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: "Oops! Notes Update Failed",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error,
                    },
                });
                });
            },
        },
        directives: {
            focus: {
                // когда привязанный элемент вставлен в DOM - переключаем фокус на элемент
                // Подробнее о директивах: https://ru.vuejs.org/v2/guide/custom-directive.html
                inserted (note) {
                    note.focus()
                }
            },

        Ripple,
        "b-tooltip": VBTooltip,
        },
    }
</script>
<style lang="scss">
    .notes {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 40px 0;
    }

    .note {
        display: flex;
        justify-content: left;

        width: 48%;
        height: 350px;
        padding: 18px 10px;
        margin-bottom: 20px;
        border-radius: 10px;

        transition: all .25s cubic-bezier(.025, .01, .50, 1);
        box-shadow: 0 30px 30px rgba(0, 0, 0, .025);

        &:hover {
            box-shadow: 0 30px 30px rgba(0, 0, 0, .04);
            transform: translate(0, -5px);
            transition-delay: 0s !important;
        }

        &.full {
            width: 100%;
        }
    }

    .text-content {
        width: 100%;
    }

    .note-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            font-weight: bold;
            color:#38B22D;
        }

        svg {
            margin-right: 12px;
            color: grey;
            cursor: pointer;

            &.active {
                color: #38B22D;
            }
            Rackham
            &:last-child {
                margin-right: 0;
            }
        }
    }

    .note-editor {
        height: auto;
        padding: 0;
        margin-bottom: 0;
        border-radius: 5px;

        font-family: "Montserrat", Helvetica, Arial, sans-serif;
        font-size: 100%;
        font-weight: bold;
        color: #494ce8;
    }

    .note-body {
        p {
            margin: 20px 0;
            max-height: 250px;
            overflow-x: hidden;
            overflow-y: auto;
            font-size:15px;
        }

        span {
            font-size: 14px;
            color: grey;
        }

        .note-editor {
            margin: 20px 0;
            max-height: 250px;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }

    .close-btn {
        color: red;
        background-color: white;
        border: none;
        cursor: pointer;
    }

    .priority {
        width: 15px;
        height: 310px;
        margin-right: 30px;

        border-radius: 20px;
        background-color: green;

        &.high {
            background-color: red;
        }

        &.low {
            background-color: #38B22D;
        }
    }
    .vertical-menu{
        color: #38B22D;
    }
</style>